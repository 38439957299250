<template>
  <div class="invoices" v-if="hasPerm('payments.view_invoice')">
    <page-header title="A facturer" icon="fa fa-file-invoice"></page-header>
    <div class="sub-header">
      <b-row>
        <b-col cols="6">
          <h2>
            <loading-gif :loading-name="loadingName"></loading-gif>
            <span v-if="!isLoading(loadingName)">
              <counter-label :counter="filteredEntities.length" label="famille"></counter-label>
              : {{ total | currency }}
            </span>
          </h2>
        </b-col>
        <b-col cols="2">
          <a
            class="btn btn-block"
            :class="showYouthFilter ? 'btn-primary' : 'btn-secondary'"
             href
            @click.prevent="toggleYouthFilter()"
          >
            <i class="fa fa-filter"></i> Filtre avancé
          </a>
        </b-col>
        <b-col cols="2">
          <b-form-select
            id="months"
            v-model="selectedMonth"
            @change="onMonthChanged()"
            >
            <b-form-select-option
              :value="item.month"
              v-for="item in allMonths"
              :key="item.month"
            >
              {{ item.label }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col cols="2">
          <b-form-select
            id="sale-type-filter"
            v-model="selectedSaleType"
            @change="onSaleTypeChanged()"
          >
            <b-form-select-option :value="elt" v-for="elt in saleTypes" v-bind:key="elt.id">
              {{ elt.name }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
      </b-row>
    </div>
    <div class="row">
      <div class="col">
        <div class="margin-bottom">
          <seances-list-filter
            v-if="showYouthFilter"
            title="Filtrer par inscription CLSH"
            @changed=onListFilterChanged($event)
          >
          </seances-list-filter>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <loading-gif :loading-name="loadingName"></loading-gif>
        <div style="overflow-y: scroll; height: 600px;">
          <table class="table table-striped" v-if="!isLoading(loadingName)">
            <tr
              v-for="entity in filteredEntities"
              :key="entity.id"
              :class="{ selected: entity === selectedEntity, }"
              class="clickable"
              @click.prevent="selectEntity(entity)"
            >
              <td>
                <span>
                  <span class="family-numbers">{{ entity.id }}</span>
                  {{ entity.name }}
                </span>
                <div v-if="entity.city && entity.city.id" class="small2">
                  {{ entity.city.name }}
                </div>
              </td>
              <td class="number text-right">
                {{ getEntityAmount(entity) | currency }}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="col-8">
        <div v-if="selectedEntity !== null" class="entity-info">
          <div class="text-right margin-bottom">
            <router-link :to="getEntityLink(selectedEntity)" class="btn btn-secondary" target="_blank">
              <i class="fa fa-arrow-circle-right"></i>
              Voir la fiche famille
            </router-link>
          </div>
          <entity-detail
            :show-reset="true"
            :initial-edit-mode="false"
            :is-family="false"
            :entity="selectedEntity"
          >
          </entity-detail>
          <entity-invoices
            :show-all="true"
            initial-tab="sales"
            @invoiced="refresh"
            :no-new-sales="true"
            :entity="selectedEntity"
            :entity-view="false"
          ></entity-invoices>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import LoadingGif from '@/components/Controls/LoadingGif'
import EntityDetail from '@/components/Entity/EntityDetail'
import EntityInvoices from '@/components/Entity/EntityInvoices'
import PageHeader from '@/components/Layout/PageHeader'
import SeancesListFilter from '@/components/Seances/Config/SeancesListFilter'
import { BackendApi } from '@/utils/http'
import { makeEntity } from '@/types/people'
import { makeSaleType } from '@/types/payments'
import CounterLabel from '@/components/Controls/CounterLabel'

export default {
  name: 'invoices-to-do',
  mixins: [BackendMixin],
  components: {
    CounterLabel, LoadingGif, PageHeader, EntityDetail, EntityInvoices, SeancesListFilter,
  },
  data() {
    return {
      loadingName: 'invoices-to-do',
      entities: [],
      selectedSaleType: null,
      fromDate: null,
      toDate: null,
      minDate: null,
      maxDate: null,
      selectedMonth: '',
      showYouthFilter: false,
      selectedYouthHome: null,
      selectedSeanceType: null,
      selectedPeriods: [],
      filterChangeInProgress: false,
      allSaleTypes: [],
      total: 0,
      selectedEntity: null,
      amountsByEntity: new Map(),
    }
  },
  watch: {
    loading: function(newValue, oldValue) {},
    saleTypes: function(saleTypes) {
      if (saleTypes.length) {
        this.selectedSaleType = saleTypes[0]
      }
    },
  },
  computed: {
    allMonths() {
      const dates = [{ month: '', label: 'Toutes les ventes', }]
      if ((this.minDate !== null) && (this.maxDate !== null)) {
        let currentDate = moment(this.minDate)
        let toDate = moment(this.maxDate)
        while (currentDate < toDate) {
          dates.push(
            { month: currentDate.format('YYYY-MM'), label: currentDate.format('MMM YYYY'), }
          )
          currentDate = currentDate.add(1, 'months')
        }
      }
      return dates
    },
    saleTypes() {
      return [makeSaleType({ name: 'Tous', })].concat(this.allSaleTypes)
    },
    filteredEntities() {
      return this.entities
    },
  },
  mounted() {
    this.onLoaded()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async onLoaded() {
      await this.loadSaleTypes()
      await this.loadEntities()
    },
    onListFilterChanged(event) {
      this.selectedYouthHome = event.youthHome
      this.selectedSeanceType = event.seanceType
      this.selectedPeriods = event.periods
      if (!this.filterChangeInProgress) {
        this.filterChangeInProgress = true
        let that = this
        setTimeout(
          () => {
            that.loadEntities()
            this.filterChangeInProgress = false
          },
          100
        )
      }
    },
    toggleYouthFilter() {
      this.showYouthFilter = !this.showYouthFilter
      this.selectedYouthHome = null
      this.selectedSeanceType = null
      this.selectedPeriods = []
      if (!this.showYouthFilter) {
        this.loadEntities()
      }
    },
    refresh() {
      this.loadEntities()
    },
    onMonthChanged() {
      this.loadEntities()
    },
    onSaleTypeChanged() {
      this.loadEntities()
    },
    selectEntity(entity) {
      this.selectedEntity = entity
    },
    getEntityAmount(entity) {
      if (this.amountsByEntity.has(entity.id)) {
        return this.amountsByEntity.get(entity.id)
      } else {
        return null
      }
    },
    getEntityLink(entity) {
      if (entity.family) {
        return { name: 'families-detail', params: { entityId: '' + entity.id, }, query: { tab: 'invoices', }, }
      } else {
        return { name: 'entities-detail', params: { entityId: '' + entity.id, }, query: { tab: 'invoices', }, }
      }
    },
    async loadSaleTypes() {
      let url = '/api/sale-types/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.allSaleTypes = resp.data.map(elt => makeSaleType(elt))
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadEntities() {
      this.startLoading(this.loadingName)
      this.selectedEntity = null
      let url = '/api/invoices-to-do/?'
      if (this.selectedMonth) {
        url += 'month=' + this.selectedMonth + '&'
      }
      if (this.selectedSaleType && this.selectedSaleType.id) {
        url += 'sale_type=' + this.selectedSaleType.id + '&'
      }
      if (this.selectedYouthHome) {
        url += 'youth_home=' + this.selectedYouthHome.id + '&'
      }
      if (this.selectedSeanceType) {
        url += 'seance_type=' + this.selectedSeanceType.id + '&'
      }
      if (this.selectedPeriods.length) {
        url += 'periods=' + this.selectedPeriods.map(elt => ('' + elt.id)).join(',')
      }
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.minDate = resp.data.min_date || null
        this.maxDate = resp.data.max_date || null
        this.fromDate = resp.data.from_date || null
        this.toDate = resp.data.to_date || null
        this.total = resp.data.total
        this.entities = resp.data.entities.map(elt => makeEntity(elt))
        const amountsMap = new Map()
        const amounts = resp.data.amounts
        for (const key of Object.keys(amounts)) {
          amountsMap.set(+key, amounts[key])
        }
        this.amountsByEntity = amountsMap
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
  },
}
</script>

<style lang="less">
table tr.selected td,
table tr.selected:nth-of-type(2n) td,
table tr.selected:nth-of-type(2n+1) td {
  background: #ccc;
}
.margin-bottom {
  margin-bottom: 10px;
}
</style>
